exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bus-routes-js": () => import("./../../../src/pages/bus-routes.js" /* webpackChunkName: "component---src-pages-bus-routes-js" */),
  "component---src-pages-campus-tour-js": () => import("./../../../src/pages/campus-tour.js" /* webpackChunkName: "component---src-pages-campus-tour-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-enquiry-thankyou-js": () => import("./../../../src/pages/enquiry-thankyou.js" /* webpackChunkName: "component---src-pages-enquiry-thankyou-js" */),
  "component---src-pages-enrolment-application-js": () => import("./../../../src/pages/enrolment-application.js" /* webpackChunkName: "component---src-pages-enrolment-application-js" */),
  "component---src-pages-enrolment-application-thankyou-js": () => import("./../../../src/pages/enrolment-application-thankyou.js" /* webpackChunkName: "component---src-pages-enrolment-application-thankyou-js" */),
  "component---src-pages-form-redirect-js": () => import("./../../../src/pages/form-redirect.js" /* webpackChunkName: "component---src-pages-form-redirect-js" */),
  "component---src-pages-high-school-js": () => import("./../../../src/pages/high-school.js" /* webpackChunkName: "component---src-pages-high-school-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-enquiry-js": () => import("./../../../src/pages/make-enquiry.js" /* webpackChunkName: "component---src-pages-make-enquiry-js" */),
  "component---src-pages-open-house-js": () => import("./../../../src/pages/open-house.js" /* webpackChunkName: "component---src-pages-open-house-js" */),
  "component---src-pages-registration-thankyou-js": () => import("./../../../src/pages/registration-thankyou.js" /* webpackChunkName: "component---src-pages-registration-thankyou-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

